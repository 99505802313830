<template>
	<div>
		<div class="row">
			<div class="col-4 form-group">
				<b-form-checkbox v-model="params.empty_stock" id="empty_stock"><b>{{ $t("monte.congelation.empty_stock") }}</b></b-form-checkbox>
			</div>
			<div class="col-4 form-group">
				<b-form-checkbox v-model="params.lot_prestockage" id="lot_prestockage"><b>{{ $t("monte.congelation.lot_prestockage") }}</b></b-form-checkbox>
			</div>
			<div class="col-4 form-group">
				<b-form-checkbox v-model="params.non_inventories" id="non_inventories"><b>{{ $t("monte.congelation.non_inventories") }}</b></b-form-checkbox>
			</div>
			<div class="col-5 form-group">
				<e-select
					id="protocole_id"
					track-by="protocole_id"
					label="protocole_label"
					:placeholder="$t('monte.congelation.selectionner_protocole')"
					:selectedLabel="$t('global.selected_label')"
					:options="protocoles"
					:searchable="true"
					:allow-empty="true"
					:show-labels="false"
					:group-select="false"
					:multiple="true"
					v-model="protocoles_selected"
					:loading="loading"
				>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-5 form-group">
				<e-select
					id="tiers"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('monte.congelation.selectionner_tiers')"
					:options="tiers"
					:searchable="true"
					:show-labels="false"
					v-model="tiers_selected"
					:loading="loading"
				>
					<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }}</template>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-2">
				<b-button variant="primary" block @click="load_stocks">{{ $t('global.rechercher') }}</b-button>
			</div>
		</div>
		<CustomTable
            id_table="semence_stock_stallion"
            ref="table"
            :items="lots"
            :busy.sync="table_busy"
            primaryKey="semencelot_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
            :externSlotColumns="['control_statut', 'semencelot_quantieme']"
        >
			<template v-slot:[`custom-slot-cell(control_statut)`]="{ data }">
				<a href="#" v-if="data.control_statut" @click.prevent="setUpControlsLot(data)">{{ $t('monte.congelation.statuts.'+data.control_statut) }}</a>
				<a href="#" v-else @click.prevent="setUpAddControlLot(data)">{{ $t('global.ajouter') }}</a>
			</template>
			<template v-slot:[`custom-slot-cell(semencelot_quantieme)`]="{ data }">
				<a href="#" @click.prevent="setUpEmplacementLot(data)">{{ data.semencelot_quantieme }}</a>
			</template>
	    </CustomTable>

		<b-modal ref="modal-destroy-lots" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.confirm_destroy_lots') }}
			</template>

			 <template slot="default">
			 	<div class="row">
					<div class="col-12 form-group">
						<label for="commentaire">{{ $t("monte.congelation.commentaire") }} *</label>
						<input class="form-control" type="text" v-model="commentaire" id="commentaire">
					</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmDestroyLot">
			                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
			                <font-awesome-icon v-else :icon="['fal', 'trash-alt']" class="mr-1"/>{{ $t('action.detruire') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>

		<b-modal ref="modal-assign-lots" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.assign_lots') }}
			</template>

			 <template slot="default">
			 	<div class="row">
					<div class="col-12 form-group">
						<label for="tiers">{{ $t("tiers.tiers") }} *</label>
						<SearchTiers 
							id="tiers"
							:tiers_selected.sync="tiers_assigned"
						/>
					</div>
					<div class="col-12 form-group">
						<label for="date">{{ $t("monte.date") }} *</label>
						<e-datepicker id="date" v-model="date" mode="date"></e-datepicker>
					</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmAssignLots">
			                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
			                <font-awesome-icon v-else :icon="['far', 'user']" class="mr-1"/>{{ $t('action.attribuer') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import Navigation from "@/mixins/Navigation.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"

	export default {
		name: 'StallionStocksListe',
		mixins: [Congelation, Navigation, ShutterCongelation],
		props: ['horse_id'],
		data () {
			return {
				table_busy: false,
				lots: [],
				config_table_hrefs: {
					'proprietaire.tiers_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'proprietaire.tiers_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToEditLot': this.setUpEditLot,
					'TableAction::goToDestroyLots': this.openConfirmDestroyLots,
					'TableAction::goToAssignLots': this.openAssignLots
				},
				params: {
					stock_empty: false,
					lot_prestockage: false,
					non_inventories: false
				},
				protocoles: [],
				protocoles_selected: [],
				tiers: [],
				tiers_selected: [],
				lots_selected: [],
				commentaire: '',
				tiers_assigned: null,
				date: new Date(),
				processing: false,
				loading: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.protocoles = await this.getAllProtocoles()
				this.tiers = await this.getHorseStockTiers(this.horse_id)
				this.loading = false
				this.load_stocks()
			},

			async load_stocks() {
				this.table_busy = true
				this.params.protocoles = this.protocoles_selected.length > 0 ? this.protocoles_selected.map(p => p.protocole_id) : null
				this.params.tiers_id = this.tiers_selected ? this.tiers_selected.tiers_id : null
				this.lots = await this.getStocksByStallion(this.horse_id, this.params)
				this.table_busy = false
			},

			async reload() {
				this.shutterPanel().close('edit-lot')
				this.$refs.table.unselectAll()
				this.tiers = await this.getHorseStockTiers(this.horse_id)
				this.load_stocks()
			},

			openConfirmDestroyLots(lots) {
				this.lots_selected = lots.map(lot => lot.semencelot_id)
				this.commentaire = ''
				this.$refs['modal-destroy-lots'].show()
			},

			async confirmDestroyLot() {
				this.processing = true
				await this.destroyLots(this.lots_selected, this.commentaire)
				this.processing = false
				this.$refs['modal-destroy-lots'].hide()
				this.reload()
			},

			openAssignLots(lots) {
				this.lots_selected = lots.map(lot => lot.semencelot_id)
				this.tiers_assigned = null
				this.date = new Date()
				this.$refs['modal-assign-lots'].show()
			},

			async confirmAssignLots() {
				if(!this.tiers_assigned || !this.date) 
					return false

				this.processing = true
				await this.assignLots(this.lots_selected, this.tiers_assigned.tiers_id, this.date)
				this.processing = false
				this.$refs['modal-assign-lots'].hide()
				this.reload()
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			SearchTiers : () => import('@/components/Contract/SearchTiers')
		}
	}

</script>